/* ========== media mixin ========== */

@mixin vw {
  @media screen and (min-width:1280px) {
    @content;
  }
}

@mixin desktop-small {
  @media screen and (max-width:1439px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width:1279px) {
    @content;
  }
}

@mixin tablet-small {
  @media screen and (max-width:1023px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width:767px) {
    @content;
  }
}

@mixin mobile-small {
  @media screen and (max-width:666px) {
    @content;
  }
}

/* ========== common mixin ========== */

@mixin col {
  padding-left: 12px;
  padding-right: 12px;
}

@mixin row {
  margin-left: -12px;
  margin-right: -12px;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin transition($trans) {
  -webkit-transition: $trans;
  -moz-transition: $trans;
  transition: $trans;
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

@mixin flex-basis($flex-basis) {
  -webkit-flex-basis: ($flex-basis);
  flex-basis: ($flex-basis);
}

@mixin display-flex($flex) {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: $flex;
}

@mixin align-item($align-item) {
  -webkit-box-align: $align-item;
  -moz-box-align: $align-item;
  -ms-flex-align: $align-item;
  -webkit-align-items: $align-item;
  align-items: $align-item;
}

@mixin align-self($align-self) {
  -webkit-align-self: $align-self;
  align-self: $align-self;
}

@mixin justify-content($justify-content) {
  -webkit-justify-content: $justify-content;
  justify-content: $justify-content;
}

@mixin flex-wrap($flex-wrap) {
  flex-wrap: $flex-wrap;
  -webkit-flex-wrap: $flex-wrap;
}

@mixin flex-direction($flex-direction) {
  flex-direction: $flex-direction;
  -webkit-flex-direction: $flex-direction;
}


/* ========== VW Functions ========== */

@function get-vw($target) {
  $vw-context: (2880*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

// GRADIENT MIXIN
@mixin gradient($color) {
  background: -webkit-linear-gradient(286.17deg, $color);
  background: -moz-linear-gradient(286.17deg, $color);
  background: -o-linear-gradient(286.17deg, $color);
  background: linear-gradient(286.17deg, $color);
}


// TRANSITION
@mixin transition {
  transition: all 0.2s linear;
}