.list-your-business-section {
    background-color: $secondary-color;
    padding: 150px 0;

    @include tablet-small {
        padding: 80px 0;
    }
}

.flash-app-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app-version {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white-color;

    .icon {
        margin-right: 35px;
    }

    span {
        font-size: 28px;
    }
}

.app-heading {
    margin-top: 40px;
    margin-bottom: 40px;
    color: $white-color;
    text-align: center;
}

.app-link {
    display: flex;
    align-items: center;
    justify-content: center;

    .col-6 {
        padding: 0 6px;
    }

    img {
        height: 96px;
        object-fit: contain;
        border-radius: 10px;
    }
}

@include tablet-small {
    .info-block {
        order: 2;
    }

    .img-block {
        order: 1;
        margin-bottom: 40px;

        .has-img {
            img {
                max-width: 60%;
                margin: 0 auto;
            }
        }
    }
}