html {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    scroll-behavior: smooth;
}

body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    padding: 0;
    font-weight: 400;
    font-family: $body-font;
    font-size: $body-font-size;
    line-height: 1.1;

    @include vw {
        font-size: get-vw($body-font-size * 2);
    }
}

.container {
    width: 100%;
    max-width: 1320px;

    @include vw {
        max-width: get-vw(2640px);
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0 0 16px 0;
    padding: 0;
    font-weight: 700;
    text-decoration: none;

    @include vw {
        margin: 0 0 get-vw(16px) 0;
    }
}

h1,
.h1 {
    font-size: 48px;

    @include vw {
        font-size: get-vw(96px);
    }

    @include mobile {
        font-size: 40px;
    }
}

h2,
.h2 {
    font-size: 40px;

    @include vw {
        font-size: get-vw(80px);
    }

    @include mobile {
        font-size: 24px;
    }
}

h3,
.h3 {
    font-size: 32px;

    @include vw {
        font-size: get-vw(64px);
    }

    @include mobile {
        font-size: 24px;
    }
}

h4,
.h4 {
    font-size: 24px;

    @include vw {
        font-size: get-vw(48px);
    }

    @include mobile {
        font-size: 20px;
    }
}

h5,
.h5 {
    font-size: 20px;

    @include vw {
        font-size: get-vw(40px);
    }

    @include mobile {
        font-size: 18px;
    }
}

h6,
.h6 {
    font-size: 18px;

    @include vw {
        font-size: get-vw(36px);
    }

    @include mobile {
        font-size: 16px;
    }
}

a {
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $primary-color;
    }
}

dl {
    dt {}
}

strong,
b {}

p {
    margin-bottom: 20px;

    @include vw {
        margin-bottom: get-vw(40px);
    }

    &:focus-visible {
        outline: none !important;
    }
}

ul,
ol {
    margin-left: 30px;
    margin-bottom: 30px;

    @include vw {
        margin-left: get-vw(60px);
        margin-bottom: get-vw(60px);
    }
}

img {
    max-width: 100%;
    vertical-align: middle;
}

hr {
    margin: 30px 0;

    @include vw {
        margin: get-vw(60px) 0;
    }
}

address {
    font-style: normal;
    margin-bottom: 20px;

    @include vw {
        margin-bottom: get-vw(40px);
    }
}

blockquote {
    margin-bottom: 20px;
    font-size: 18px;

    @include vw {
        margin-bottom: get-vw(40px);
        font-size: get-vw(36px);
    }
}

.list-style-none {
    list-style: none;
}

/* ========== COMMON CLASS ========== */

.d-none {
    display: none;
}

.d-flex {
    @include display-flex(flex);
}

.align-v-center {
    @include align-item(center);
}

.align-start {
    @include align-item(flex-start);
}

.align-end {
    @include align-item(flex-end);
}

.align-self-start {
    @include align-self(flex-start);
}

.justify-center {
    @include justify-content(center);
}

.justify-right {
    @include justify-content(flex-end);
}

.justify-space {
    @include justify-content(space-between);
}

.flex-wrap {
    @include flex-wrap(wrap);
}

.flex-direction {
    @include flex-direction(column);
}

.clearfix,
.clearfix {

    &:before,
    &:after {
        display: table;
        content: " ";
    }

    &:after {
        clear: both;
    }
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.padding-none {
    padding: 0 !important;
}

.margin-none {
    margin: 0 !important;
}

.link {
    color: $primary-color;
    text-decoration: underline;
}

.desktop-view {
    @include tablet-small {
        display: none;
    }
}

.mobile-view {
    display: none;

    @include tablet-small {
        display: block;
    }
}

.show-for-mobile {
    display: none;

    @include mobile {
        display: block;
    }
}

.small-font {
    font-size: calc($body-font-size - 2px);

    @include vw {
        font-size: get-vw(calc($body-font-size - 2px) * 2);
    }
}

// BUTTON
.button {
    display: block;
    background-color: $secondary-color;
    color: $white-color;
    padding: 10px 40px;
    border-radius: 5px;
    font-size: 18px;
    transition: all 0.2s linear;
    border: 1px solid $secondary-color;

    @include vw {
        padding: get-vw(20px) get-vw(80px);
        border-radius: get-vw(10px);
        font-size: get-vw(36px);
    }

    &:hover {
        background-color: $white-color;
        color: $secondary-color;
    }
}

.button-secondary {
    display: block;
    background-color: $primary-color;
    color: $white-color;
    padding: 10px 40px;
    border-radius: 5px;
    font-size: 18px;
    transition: all 0.2s linear;
    border: 1px solid $primary-color;

    @include vw {
        padding: get-vw(20px) get-vw(80px);
        border-radius: get-vw(10px);
        font-size: get-vw(36px);
    }

    &:hover {
        background-color: $white-color;
        color: $primary-color;
    }
}


// SPACING CLASSIS
.p-0 {
    padding: 0;
}

.p-l-0 {
    padding-left: 0;
}

.p-r-0 {
    padding-right: 0;
}

.m-0 {
    margin: 0;
}

.m-l-auto {
    margin-left: auto;
}

.m-l-0 {
    margin-left: 0px;
}

.m-l-6 {
    margin-left: 6px;
}

.m-l-8 {
    margin-left: 8px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-l-16 {
    margin-left: 16px;
}

.m-l-26 {
    margin-left: 26px;
}

.m-l-55 {
    margin-left: 55px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-r-6 {
    margin-right: 6px;
}

.m-r-8 {
    margin-right: 8px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-15 {
    margin-right: 15px;

    @include vw {
        margin-right: get-vw(32px);
    }
}

.m-r-16 {
    margin-right: 16px;

    @include vw {
        margin-right: get-vw(30px);
    }
}

.m-r-26 {
    margin-right: 26px;
}

.m-r-55 {
    margin-right: 55px;

    @include vw {
        margin-right: get-vw(110px);
    }
}

.m-t-0 {
    margin-top: 0px;
}

.m-t-8 {
    margin-top: 8px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-16 {
    margin-top: 16px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-26 {
    margin-top: 26px;
}

.m-t-28 {
    margin-top: 28px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-35 {
    margin-top: 35px;

    @include vw {
        margin-top: get-vw(70px);
    }
}

.m-t-40 {
    margin-top: 40px;
}

.m-t-60 {
    margin-top: 60px;

    @include vw {
        margin-top: get-vw(120px);
    }
}

.m-t-80 {
    margin-top: 80px;

    @include vw {
        margin-top: get-vw(160px);
    }
}

.m-b-0 {
    margin-bottom: 0px;
}

.m-b-8 {
    margin-bottom: 8px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-16 {
    margin-bottom: 16px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-26 {
    margin-bottom: 26px;
}

.m-b-28 {
    margin-bottom: 28px;

    @include vw {
        margin-bottom: get-vw(56px);
    }
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-b-32 {
    margin-bottom: 32px;
}

.m-b-40 {
    margin-bottom: 40px;
}

.m-b-60 {
    margin-bottom: 60px;

    @include vw {
        margin-bottom: get-vw(120px);
    }
}

.m-b-80 {
    margin-bottom: 80px;
}

// SECTION GAP
.section-gap {
    margin-bottom: 100px;

    @include vw {
        margin-bottom: get-vw(200px);
    }

    @include mobile {
        margin-bottom: 40px;
    }
}

.heading-wrapper {
    .heading {
        color: $primary-color;
    }

    p {
        &:last-child {
            margin-top: 16px;
            margin-bottom: 0;
        }
    }
}

// MODAL STYLE
.modal {
    .notification-banner {
        background-color: #615EF01A;
        color: #000;
        padding: 16px;

        a {
            @extend .link;
        }
    }
}

.modal-header {
    text-align: left;
    border-bottom: none;
    align-items: flex-start;
}

.modal-title {
    span {
        color: $secondary-color;
    }
}

.modal-body {
    .form-control {
        &:focus {
            box-shadow: none;
        }
    }
}

.modal-footer {
    border-top: none;

    .button {
        width: 100%;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 623px;
    }
}

.btn-close {
    width: 24px;
    height: 24px;
    background: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clip-path='url(%23clip0_478_4809)'%3E%3Cpath d='M11.9997 10.586L16.9497 5.63599L18.3637 7.04999L13.4137 12L18.3637 16.95L16.9497 18.364L11.9997 13.414L7.04974 18.364L5.63574 16.95L10.5857 12L5.63574 7.04999L7.04974 5.63599L11.9997 10.586Z' fill='%23F8A11F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_478_4809'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    line-height: normal;
    background-repeat: no-repeat;
    background-position: top center;
    min-width: 24px;
}

.otp-code-block {
    @include mobile {
        flex-wrap: wrap;
        padding: 0 5px;
    }

    input {
        margin-right: 20px;
        width: 80px;
        height: 58px;
        text-align: center;
        padding: 10px 20px;
        font-size: 30px;

        @include mobile {
            width: calc(100% / 6 - 10px);
            padding: 5px;
            font-size: 18px;
            height: 45px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

// REVERSE COL
.reverse-col {
    .col {
        &:first-child {
            @include mobile {
                order: 2;
            }
        }
    }
}

// INPUT STYLING
.form-field {
    margin-bottom: 15px;

    @include vw {
        margin-bottom: get-vw(30px);
    }
}

.form-input {
    padding: 14px 20px;
    background: #FFFFFF;
    border: 2px solid #E2E8F0;
    border-radius: 5px;
    outline: none;
    width: 100%;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    line-height: 21px;

    @include vw {
        padding: get-vw(28px) get-vw(40px);
        border-width: get-vw(4px);
        border-radius: get-vw(10px);
        font-size: get-vw(28px);
        line-height: get-vw(42px);
    }
}

.form-select {
    @extend .form-input;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clip-path='url(%23clip0_259_2457)'%3E%3Cpath d='M12.0007 13.172L16.9507 8.22198L18.3647 9.63598L12.0007 16L5.63672 9.63598L7.05072 8.22198L12.0007 13.172Z' fill='%23222662'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_259_2457'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: right 18px center;

    @include vw {
        background-position: right get-vw(36px) center;
        background-size: get-vw(48px);
    }

    &:focus {
        border-color: #E2E8F0;
        box-shadow: none;
    }
}

// PAGE HEADING
.page-heading {
    &--image {
        position: relative;
        height: 428px;
        @include display-flex(flex);
        @include align-item(center);
        @include justify-content(center);

        @include vw {
            height: get-vw(856px);
        }

        .bg-image {
            position: absolute;
            width: 100%;
            height: 100%;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(34, 38, 98, 0) 35.1%, $primary-color 100%);
                bottom: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        h2 {
            position: relative;
            z-index: 1;
            color: $white-color;
        }
    }
}