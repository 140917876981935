.row {
    @include mobile {
        margin-left: -20px;
        margin-right: -20px;
    }

    >* {
        @include mobile {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.container {
    @include mobile {
        padding-left: 20px;
        padding-right: 20px;
    }
}