.message-section {
    .left-panel-wrapper {
        // background-color: #F4F4F4;
    }
}

.message-heading {
    padding: 24px;
    border-bottom: 1px solid rgb(0 0 0 / 8%);
}

.msg-counter {
    background-color: #EDF2F7;
    padding: 2px 8px;
    border-radius: 24px;
    font-size: 12px;
    line-height: 18px;
}

.message-search-box {
    padding: 12px 0;

    input {
        background-color: #F3F3F3;
        padding: 14px 20px;
        border-radius: 12px;
        border: none;
    }
}

.message-listing {
    padding: 0 16px;

    @include mobile {
        display: none;

        &.is-active {
            display: block;
        }
    }

    .message-item {
        padding: 12px;
    }
}

.message-item {
    margin-bottom: 8px;

    .has-img {
        min-width: 48px;
        width: 48px;
        height: 48px;
        margin-right: 16px;

        img {
            height: 100%;
            object-fit: cover;
            border-radius: 12px;
        }
    }

    .msg-info {
        position: relative;
        width: 100%;
    }

    &.active,
    &:hover {
        background-color: rgba(97, 94, 240, 0.06);
        border-radius: 12px;
        cursor: pointer;
    }
}

.msg-info {
    .vendor-title {
        padding-right: 45px;
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
    }

    .msg-time {
        position: absolute;
        top: 0;
        right: 0;
        color: #000000;
        opacity: 0.3;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
    }

    .meg-highlight {
        color: #999999;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
    }
}

.not-satisfy {
    background-color: rgba(173, 0, 0, 0.1);
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 18px;
    color: #AD0000;
    font-weight: 600;
}

.satisfied {
    background-color: #C6F6D5;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    line-height: 18px;
    color: #38A169;
    font-weight: 600;
}

// RIGHT SIDE
.chat-header {
    padding: 18px 12px;
    border-bottom: 1px solid rgb(0 0 0 / 8%);

    @include mobile {
        flex-wrap: wrap;
    }

    .vendor-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
        padding-right: 0;
    }

    .messenger-status-dot {
        background-color: #ff0000;
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 8px;

        &.is-online {
            background-color: #68D391;
        }
    }

    .messenger-status {
        color: #000000;
        opacity: 0.6;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        display: flex;
        align-items: center;
    }

    .btn {
        padding: 10px 16px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.25;
        border: none;

        @include mobile {
            padding: 8px 10px;
            font-size: 12px;
        }
    }
}

.btn-not-satisfied {
    background: rgba(173, 0, 0, 0.1);
    color: #AD0000;

    &:hover {
        background: rgba(173, 0, 0, 0.2);
        color: #AD0000;
    }

    svg {
        fill: #AD0000;
    }
}

.btn-satisfied {
    background: rgba(0, 191, 54, 0.18);
    color: #008B38;

    &:hover {
        background: rgba(0, 191, 54, 0.20);
        color: #008B38;
    }
}

.btn-call {
    background: rgba(97, 94, 240, 0.1);
    color: #222662;

    &:hover {
        background: rgba(97, 94, 240, 0.2);
        color: #222662;
    }
}

.msg-right-panel {}

.chat-body {
    padding: 24px 12px;

    .receiver {
        display: flex;
        margin-bottom: 32px;
    }

    .sender {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 32px;

        .message {
            background-color: #F5F5F5;
            color: #212121;
        }

        .profile-pic {
            margin-right: 0;
            margin-left: 16px;
        }

        .time {
            text-align: right;
        }
    }

    .profile-pic {
        min-width: 40px;
        width: 40px;
        height: 40px;
        margin-right: 16px;
    }

    .message {
        padding: 8px 16px;
        background-color: #222662;
        color: #fff;
        border-radius: 12px;
        width: fit-content;
        margin-bottom: 10px;

        p {
            font-size: 14px;
            line-height: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .time {
        font-size: 14px;
        line-height: 20px;
        color: #9E9E9E;
    }
}

.chat-footer {
    padding: 24px 12px;
}

.chat-box {
    display: flex;
    align-items: center;

    .icon-share {
        margin-right: 24px;
    }

    .input-wrapper {
        position: relative;
        width: 100%;
    }

    .icon-send {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
}