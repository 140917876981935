.list-of-vendor-section {
    .left-panel-wrapper {
        padding: 20px;
        background-color: #F4F4F4;
        border-radius: 5px;

        @include vw {
            padding: get-vw(40px);
            border-radius: get-vw(10px);
        }

        .left-panel-nav {
            @include mobile {
                flex-wrap: wrap;
            }
        }

        .list-item {
            margin-bottom: 20px;

            @include vw {
                margin-bottom: get-vw(40px);
            }

            @include mobile {
                width: 100%;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                .item-link {
                    color: $secondary-color;

                    .counter {
                        opacity: 1;
                    }
                }
            }
        }

        .item-link {
            color: #262626;
            @include transition;

            .active,
            &:hover {
                color: $secondary-color;

                .counter {
                    opacity: 1;
                }
            }

            .counter {
                opacity: 0.35;
                margin-left: auto;
            }
        }
    }

    .facets-container {
        background-color: $gray-color-100;
        border-radius: 5px;
        padding: 10px 22px;

        @include vw {
            padding: get-vw(20px) get-vw(44px);
            border-radius: get-vw(10px);
        }

        p {
            margin-bottom: 0;
        }

        .sort-on-top {
            @include mobile {
                flex-wrap: wrap;

                >div:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .total-results-counter {
        margin-right: 150px;

        @include vw {
            margin-right: get-vw(300px);
        }

        @include tablet-small {
            margin-right: auto;
        }
    }

    .sort-by-dropdown {
        margin-right: 40px;

        @include vw {
            margin-right: get-vw(80px);
        }
    }

    .right-panel-wrapper {
        @include tablet-small {
            margin-top: 40px;
        }
    }
}

.form-dropdown {
    label {
        margin-right: 10px;

        @include vw {
            margin-right: get-vw(20px);
        }
    }

    .select-wrapper {
        position: relative;

        &::after {
            content: "";
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='4' viewBox='0 0 8 4' fill='none'%3E%3Cpath d='M3.68381 4L0 0H7.36871L3.68381 4Z' fill='%2322262A'/%3E%3C/svg%3E");
            width: 7px;
            height: 4px;
            position: absolute;
            top: 50%;
            right: 14px;
            transform: translateY(-50%);

            @include vw {
                width: get-vw(14px);
                height: get-vw(8px);
                right: get-vw(28px);
            }
        }
    }

    select {
        appearance: none;
        border: 2px solid #F1F3F4;
        border-radius: 5px;
        padding: 5px 30px 5px 16px;
        outline: none;
        min-width: 125px;

        @include vw {
            border-width: get-vw(4px);
            border-radius: get-vw(10px);
            padding: get-vw(10px) get-vw(60px) get-vw(10px) get-vw(32px);
        }
    }
}

.vendor-list-wrapper {
    .list-item {
        background-color: #F4F4F4;
        border-radius: 5px;
        margin-bottom: 22px;

        @include mobile {
            flex-wrap: wrap;
            justify-content: center;

            .image-wrapper {
                margin-top: 20px;
            }
        }

        @include vw {
            border-radius: get-vw(10px);
            margin-bottom: get-vw(44px);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .vendor-location {
        li {
            position: relative;
            padding-right: 15px;
            margin-right: 10px;
            color: #8E8E8E;

            @include vw {
                padding-right: get-vw(30px);
                margin-right: get-vw(20px);
            }

            &::after {
                content: "";
                display: inline-block;
                width: 6px;
                height: 6px;
                background-color: #D9D9D9;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);

                @include vw {
                    width: get-vw(12px);
                    height: get-vw(12px);
                }
            }

            &:first-child {
                color: #222662;
            }

            &:last-child {
                margin-right: 0;
                padding-right: 0;

                &::after {
                    display: none;
                }
            }
        }
    }

    .button-wrapper {
        .icon {
            margin-right: 6px;

            @include vw {
                margin-right: get-vw(12px);
            }
        }

        svg {
            fill: #fff;
        }

        .button,
        .button-secondary {
            padding: 10px 15px;

            @include vw {
                padding: get-vw(20px) get-vw(30px);
            }
        }

        .button {
            &:hover {
                svg {
                    fill: $secondary-color;
                }
            }
        }

        .button-secondary {
            &:hover {
                svg {
                    fill: $primary-color;
                }
            }
        }
    }
}

.image-wrapper {
    position: relative;
    min-width: 251px;

    @include vw {
        min-width: get-vw(502px);
    }

    img {
        border-radius: 5px;
        width: 251px;
        height: 228px;
        object-fit: cover;

        @include vw {
            border-radius: get-vw(10px);
            width: get-vw(502px);
            height: get-vw(456px);
        }
    }

    .label-location-distance {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #222662;
        color: #fff;
        padding: 6px 11px;
        border-top-left-radius: 5px;

        @include vw {
            padding: get-vw(12px) get-vw(22px);
            border-top-left-radius: get-vw(10px);
        }
    }
}

.vendor-info {
    padding: 20px;

    @include vw {
        padding: get-vw(40px);
    }
}

.vendor-title {
    color: #222662;
}

.rating-start {
    .icon {
        margin-right: 10px;

        @include vw {
            margin-right: get-vw(20px);
        }

        svg {
            fill: #FFC600;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .star-no-fill {
        svg {
            fill: #C1C8CE;
        }
    }
}

.rating {
    background-color: #00C247;
    color: #ffffff;
    padding: 1px 7px;
    border-radius: 4px;
    font-weight: 700;
    line-height: 27px;

    @include vw {
        padding: get-vw(2px) get-vw(14px);
        border-radius: get-vw(8px);
        line-height: get-vw(54px);
    }
}

.rating-counter {
    color: #696969;
}

// PAGINATION STYLE
.pagination-wrapper {
    background-color: #F4F4F4;
    border-radius: 5px;

    @include vw {
        border-radius: get-vw(10px);
    }

    .pagination-item {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        a {
            display: block;
            padding: 17px 25px;
            color: #22262A;
        }

        &.active,
        &:hover {
            background-color: $secondary-color;

            a {
                color: $white-color;
            }
        }
    }
}