footer {
    background-color: $primary-color;
    padding-top: 50px;
    padding-bottom: 50px;
}

.footer-top-wrapper {
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    @include mobile {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.social-wrapper {
    margin-left: auto;

    @include mobile {
        margin-left: unset;
        margin-top: 15px;
    }

    .icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $secondary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;

        &:first-child {
            margin-left: 0;
        }

        svg {
            fill: $white-color;
        }

        &:hover {
            background-color: $white-color;

            svg {
                fill: $primary-color;
            }
        }
    }
}

.footer-navigation {
    padding: 50px 0;

    .nav-title {
        @include mobile {
            text-align: center;
        }

        h6 {
            color: $white-color;
            margin-bottom: 24px;
        }
    }

    nav {
        @include tablet-small {
            justify-content: center;
        }
    }

    ul {
        @include mobile {
            flex-wrap: wrap;
            justify-content: center;
        }

        li {
            margin-left: 38px;

            @include tablet-small {
                margin-left: 16px;
            }

            @include mobile {
                margin-bottom: 8px;
            }

            &:first-child {
                margin-left: 0;
            }
        }

        a {
            color: $white-color;
            opacity: 0.75;
            font-size: 14px;

            &:hover {
                color: $secondary-color;
                opacity: 1;
            }
        }
    }

    .button-wrapper {
        margin-left: auto;

        @include mobile {
            margin: 0 auto;
            margin-top: 16px;
        }
    }

    .button {
        @include mobile {
            padding: 5px 18px;
            font-size: 16px;
        }
    }
}

.footer-copyrights {
    p {
        color: $white-color;
        font-size: 14px;
        margin-bottom: 0;
    }
}