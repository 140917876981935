@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

// FONT FAMILY
$primary-font: 'Inter', sans-serif;

// GLOBAL VARIABLE
$body-font-size: 16px;

$body-font-color: #2E3E5C;
$body-font: 'Inter', sans-serif;

// MAIN COLOR
$primary-color: #222662;
$primary-color-100: #9193B1;
$primary-color-200: #7A7DA1;
$primary-color-300: #646791;
$primary-color-400: #4E5181;
$primary-color-500: #383C72;

$secondary-color: #F8A11F;
$secondary-color-100: #FCD08F;
$secondary-color-200: #FBC779;
$secondary-color-300: #FABD62;
$secondary-color-400: #F9B44C;
$secondary-color-500: #F9AA35;

// COLOR VARIABLES
$white-color: #FFFFFF;
$black-color: #000000;

// ALERT & STATUS COLOR
$Success: #00C247;
$info: #004CE8;
$warning: #FFE16A;
$error: #FF3333;
$disabled: #D8D8D8;
$disabled-button: #879AC1;

// GRAYSCALE COLOR
$gray-color-100: #F5F5F5;
$gray-color-200: #EEEEEE;
$gray-color-300: #E0E0E0;
$gray-color-400: #BDBDBD;
$gray-color-500: #9E9E9E;
$gray-color-600: #757575;
$gray-color-700: #616161;
$gray-color-800: #424242;
$gray-color-900: #212121;


// GRADIENTS COLOR BLUE
$blue-dark: #004CE8;
$blue-light: #6F9EFF;

$gradient-blue: $blue-dark 0%, $blue-light 100%;

// GRADIENTS COLOR GREEN
$green-dark: #00C247;
$green-light: #73FFA6;

$gradient-green: $green-dark 0%, $green-light 100%;

// GRADIENTS COLOR YELLOW
$yellow-dark: #FFE16A;
$yellow-light: #FFE58036;

$gradient-yellow: $yellow-dark 0%, $yellow-light 100%;

// GRADIENTS COLOR RED
$red-dark: #FF3333;
$red-light: #FF8A9B;

$gradient-red: $red-dark 36.98%, $red-light 100%;

// DARK COLOR
$dark-100: #35383F;
$dark-200: #1F222A;
$dark-300: #181A20;

// BACKGROUND COLORS
$bg-light-blue: #F6FAFD;
$bg-light-red: #FFF5F5;
$bg-light-yellow: #FFFEE0;
$bg-light-purple: #FCF4FF;