.progress-indicator-section {
    background-color: $primary-color;
    padding: 75px 0;

    @include mobile {
        padding: 40px 0;
    }
}

.progress-item {

    h3,
    p {
        color: $white-color;

        @include mobile {
            font-size: 12px;
        }
    }

    h3 {
        font-size: 48px;

        @include tablet-small {
            font-size: 40px;
        }

        @include mobile {
            font-size: 20px;
            margin-bottom: 5px;
        }
    }

    p {
        margin-bottom: 0;
    }
}

.progress-indicator-wrapper {
    position: relative;
}

.img-line-top {
    position: absolute;
    top: -160px;
    left: 0;
    height: 107px;

    @include mobile {
        top: -70px;
        height: auto;
    }

    img {
        height: 100%;
    }
}

.img-line-bottom {
    position: absolute;
    bottom: -84px;
    left: -100px;
    height: 97px;

    img {
        height: 100%;
    }
}

.img-bell {
    position: absolute;
    top: -120px;
    right: 0;

    @include mobile {
        top: -60px;
    }
}

.img-pot {
    position: absolute;
    bottom: -125px;
    left: 20%;

    @include mobile {
        bottom: -70px;
    }
}

@include mobile {
    .img-bell img {
        max-width: 130px;
    }

    .img-line-bottom img {
        width: 150px;
    }

    .img-line-top img {
        max-width: 80px;
        height: auto;
    }

    .img-pot img {
        max-width: 80px;
    }
}