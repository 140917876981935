.shop-info-wrapper {
    background-color: $gray-color-300;
    border-radius: 14px;
    padding-top: 160px;
    margin-top: 150px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;

    .shop-img {
        margin-top: -310px;
        margin-bottom: 30px;
    }
}

.shop-info {
    padding: 0 16px;

    h5 {
        color: $primary-color;
    }

    p {
        color: $gray-color-700;
        line-height: 28px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.review-wrapper {
    background-color: $gray-color-300;
    border-radius: 14px;
    padding: 35px;
    margin-top: 150px;

    @include tablet-small {
        margin-top: 60px;
    }
}

.review-heading {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    h5 {
        color: $primary-color;
        margin-bottom: 0;
        margin-right: 10px;
    }

    svg {
        fill: #FFE603;
        margin-right: 6px;
    }

    p {
        margin-bottom: 0;
        font-size: 22px;
    }
}

.review-item {
    position: relative;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    .has-img {
        margin-right: 16px;
    }

    .reviews {
        position: absolute;
        right: 0;
        top: auto;
        bottom: 15px;
        display: flex;
        align-items: center;

        svg {
            fill: #FFE603;
            margin-right: 6px;
        }

        p {
            margin-bottom: 0;
        }
    }
}