.testimonial-inner {
    @include mobile {
        margin-top: 15px;
    }

    h2 {
        margin-bottom: 20px;
    }
}

.testimonial-wrapper {
    .has-img {
        img {
            max-height: 419px;
        }
    }

    .image-block {
        @include tablet-small {
            display: flex;
            align-items: center;
        }
    }

    .slick-slide {
        padding: 0;
    }
}

.rating-wrapper {
    margin-bottom: 10px;

    .icon {
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.testimonial-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
}

.testimonial-des {
    font-size: 18px;
    color: #000;
    opacity: 0.5;
    line-height: 29px;
    letter-spacing: 0.2px;
    margin-bottom: 24px;
}

.author-wrapper {
    img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 20px;
    }

    .author-info {
        margin-left: 15px;

        p {
            font-size: 18px;
            margin-bottom: 5px;
            font-weight: 600;
        }

        span {
            font-size: 16px;
            color: #000;
            opacity: 0.5;
        }
    }
}

.testimonial-arrows {
    .slick-arrow {
        color: #000;
        font-size: 0;
        line-height: 1;
        width: 40px;
        height: 40px;
        background-color: #C4C4C4;
        z-index: 99;
        border-radius: 50%;

        &.slick-disabled {
            cursor: not-allowed;
        }
    }

    .slick-next {
        top: auto;
        right: 30px;
        left: auto;
        bottom: 0;

        &:before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.2925 5.29303C10.48 5.10556 10.7343 5.00024 10.9995 5.00024C11.2647 5.00024 11.519 5.10556 11.7065 5.29303L15.7065 9.29303C15.894 9.48056 15.9993 9.73487 15.9993 10C15.9993 10.2652 15.894 10.5195 15.7065 10.707L11.7065 14.707C11.5179 14.8892 11.2653 14.99 11.0031 14.9877C10.7409 14.9854 10.4901 14.8803 10.3047 14.6948C10.1193 14.5094 10.0141 14.2586 10.0118 13.9964C10.0096 13.7342 10.1104 13.4816 10.2925 13.293L12.5855 11H4.99951C4.7343 11 4.47994 10.8947 4.29241 10.7071C4.10487 10.5196 3.99951 10.2652 3.99951 10C3.99951 9.73481 4.10487 9.48046 4.29241 9.29292C4.47994 9.10539 4.7343 9.00003 4.99951 9.00003H12.5855L10.2925 6.70703C10.105 6.5195 9.99973 6.26519 9.99973 6.00003C9.99973 5.73487 10.105 5.48056 10.2925 5.29303Z' fill='black'/%3E%3C/svg%3E%0A");
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 20px;
            height: 20px;
            transform: translate(-50%, -50%);
            opacity: 1;
        }

        &:hover {
            &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.2925 5.29303C10.48 5.10556 10.7343 5.00024 10.9995 5.00024C11.2647 5.00024 11.519 5.10556 11.7065 5.29303L15.7065 9.29303C15.894 9.48056 15.9993 9.73487 15.9993 10C15.9993 10.2652 15.894 10.5195 15.7065 10.707L11.7065 14.707C11.5179 14.8892 11.2653 14.99 11.0031 14.9877C10.7409 14.9854 10.4901 14.8803 10.3047 14.6948C10.1193 14.5094 10.0141 14.2586 10.0118 13.9964C10.0096 13.7342 10.1104 13.4816 10.2925 13.293L12.5855 11H4.99951C4.7343 11 4.47994 10.8947 4.29241 10.7071C4.10487 10.5196 3.99951 10.2652 3.99951 10C3.99951 9.73481 4.10487 9.48046 4.29241 9.29292C4.47994 9.10539 4.7343 9.00003 4.99951 9.00003H12.5855L10.2925 6.70703C10.105 6.5195 9.99973 6.26519 9.99973 6.00003C9.99973 5.73487 10.105 5.48056 10.2925 5.29303Z' fill='%23FEBD17'/%3E%3C/svg%3E%0A");
            }
        }
    }

    .slick-prev {
        top: auto;
        right: 90px;
        left: auto;
        bottom: 0;

        &:before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.70651 5.29303C9.51898 5.10556 9.26468 5.00024 8.99951 5.00024C8.73435 5.00024 8.48004 5.10556 8.29251 5.29303L4.29251 9.29303C4.10504 9.48056 3.99973 9.73487 3.99973 10C3.99973 10.2652 4.10504 10.5195 4.29251 10.707L8.29251 14.707C8.48111 14.8892 8.73372 14.99 8.99591 14.9877C9.25811 14.9854 9.50892 14.8803 9.69433 14.6948C9.87974 14.5094 9.98491 14.2586 9.98719 13.9964C9.98946 13.7342 9.88867 13.4816 9.70651 13.293L7.41351 11H14.9995C15.2647 11 15.5191 10.8947 15.7066 10.7071C15.8942 10.5196 15.9995 10.2652 15.9995 10C15.9995 9.73481 15.8942 9.48046 15.7066 9.29292C15.5191 9.10539 15.2647 9.00003 14.9995 9.00003H7.41351L9.70651 6.70703C9.89398 6.5195 9.9993 6.26519 9.9993 6.00003C9.9993 5.73487 9.89398 5.48056 9.70651 5.29303Z' fill='black'/%3E%3C/svg%3E%0A");
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 20px;
            height: 20px;
            transform: translate(-50%, -50%);
            opacity: 1;

        }

        &:hover {
            &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.70651 5.29303C9.51898 5.10556 9.26468 5.00024 8.99951 5.00024C8.73435 5.00024 8.48004 5.10556 8.29251 5.29303L4.29251 9.29303C4.10504 9.48056 3.99973 9.73487 3.99973 10C3.99973 10.2652 4.10504 10.5195 4.29251 10.707L8.29251 14.707C8.48111 14.8892 8.73372 14.99 8.99591 14.9877C9.25811 14.9854 9.50892 14.8803 9.69433 14.6948C9.87974 14.5094 9.98491 14.2586 9.98719 13.9964C9.98946 13.7342 9.88867 13.4816 9.70651 13.293L7.41351 11H14.9995C15.2647 11 15.5191 10.8947 15.7066 10.7071C15.8942 10.5196 15.9995 10.2652 15.9995 10C15.9995 9.73481 15.8942 9.48046 15.7066 9.29292C15.5191 9.10539 15.2647 9.00003 14.9995 9.00003H7.41351L9.70651 6.70703C9.89398 6.5195 9.9993 6.26519 9.9993 6.00003C9.9993 5.73487 9.89398 5.48056 9.70651 5.29303Z' fill='%23FEBD17'/%3E%3C/svg%3E%0A");
            }
        }
    }

    .slick-next:before,
    .slick-prev:before {
        color: #000;
    }
}