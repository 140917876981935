.navbar-nav {
    .nav-item {
        margin-right: 30px;
        padding-top: 35px;
        padding-bottom: 36px;
        position: relative;

        @include vw {
            margin-right: get-vw(60px);
            padding-top: get-vw(70px);
            padding-bottom: get-vw(72px);
        }

        &:last-child {
            margin-right: 0;
        }

        &.active {
            &:after {
                content: "";
                width: 100%;
                height: 7px;
                background-color: $secondary-color;
                display: block;
                position: absolute;
                bottom: 0;

                @include vw {
                    height: get-vw(14px);
                }
            }

            .nav-link {
                color: $secondary-color;
            }
        }

        .nav-link {
            padding: 0;
            font-size: $body-font-size;
            line-height: 19px;

            @include vw {
                line-height: get-vw(38px);
                font-size: get-vw(32px);
            }

            &:hover {
                color: $secondary-color;
            }
        }
    }
}

.header-right-side-wrapper {
    .icon-wrapper {
        @include mobile {
            margin-right: 0;
        }
    }

    .user-login {
        @include mobile {
            display: none;
        }

        a {
            color: $black-color;

            &:hover {
                color: $secondary-color;
            }
        }

        .icon {
            width: 35px;
            height: 35px;

            img {
                min-width: 35px;
                width: 35px;
                height: 35px;

                @include vw {
                    min-width: get-vw(70px);
                    width: get-vw(70px);
                    height: get-vw(70px);
                }
            }

            @include vw {
                width: get-vw(70px);
                height: get-vw(70px);
            }
        }
    }

    .icon-wrapper {
        .icon {
            width: 56px;
            height: 44px;
            background-color: #615EF01A;
            border-radius: 8px;

            @include vw {
                width: get-vw(112px);
                height: get-vw(88px);
                border-radius: get-vw(16px);
            }

            @include mobile {
                width: 35px;
                height: 35px;
            }

            svg {
                fill: $primary-color;
                width: 24px;
                height: 24px;

                @include vw {
                    width: get-vw(48px);
                    height: get-vw(48px);
                }

                @include mobile {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .icon-link {
            &:hover {
                .icon {
                    background-color: $secondary-color;
                }

                svg {
                    fill: $white-color;
                }
            }
        }
    }

}

// BOOTStRAP RESET STYLING
.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
}

.logo-wrapper {

    svg {
        height: 46px;
        width: 162px;

        @include vw {
            width: get-vw(324px);
            height: get-vw(92px);
        }

        @include mobile {
            width: 120px;
        }
    }
}

// Header Responsive
@include tablet-small {
    .logo-wrapper {
        width: 100%;
        margin-left: 15px;
    }

    .main-header.header {
        padding: 20px 12px;

        @include tablet-small {
            padding: 10px 0;
        }
    }

    .nav-responsive {
        position: fixed;
        /* position: absolute; */
        left: 0;
        top: 86px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 99999;
        transition: none;
        padding: 40px 30px;
        display: none;

        @include tablet-small {
            top: 66px;
            padding: 20px;
        }

        &.show {
            display: block;
        }

        &.collapse {
            visibility: visible;
        }

        .navbar-nav .nav-item {
            margin-right: 0;
            padding-top: 0;
            padding-bottom: 36px;
            text-align: left;

            @include tablet-small {
                padding-bottom: 20px;
            }
        }

        .navbar-nav .nav-item.active:after {
            display: none;
        }
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
    }
}

.slick-slider {
    width: 100%;
}

.slide-item {
    padding-right: 15px;

    @include vw {
        padding-right: get-vw(15px);
    }

    @include mobile {
        padding-right: 0;
    }
}

.slick-arrow {
    position: absolute;
    right: 0;
    // top: 39px;
    width: 30px;
    height: 30px;
    background-color: #F4F4F4;
    border-radius: 50%;
    // @include translate(0, 00%);

    @include vw {
        width: get-vw(30px);
        height: get-vw(30px);
        top: get-vw(-5px);
    }

    &.slick-next {
        &::before {
            display: block;
            margin: auto;
            content: "";
            width: 9px;
            height: 15px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15' fill='none' stroke='%23000000'%3E%3Cpath d='M1.6543 2.12354L7.13355 7.60279L1.6543 13.0821' stroke-width='3.29944'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;

            @include vw {
                width: get-vw(9px);
                height: get-vw(15px);
            }
        }

        &:hover {
            &::before {
                //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15' fill='none' stroke='%23FEBD17'%3E%3Cpath d='M1.6543 2.12354L7.13355 7.60279L1.6543 13.0821' stroke-width='3.29944'/%3E%3C/svg%3E");
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15' fill='none' stroke='%23FEBD17'%3E%3Cpath d='M1.6543 2.12354L7.13355 7.60279L1.6543 13.0821' stroke-width='3.29944'/%3E%3C/svg%3E");
            }
        }
    }

    &.slick-prev {
        left: auto;
        right: 55px;

        // @include vw {
        //     right: get-vw(55px);
        // }

        &::before {
            display: block;
            margin: auto;
            content: "";
            width: 9px;
            height: 15px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15' fill='none' stroke='%23000000'%3E%3Cpath d='M8.3418 13.0815L2.86254 7.60229L8.3418 2.12303' stroke-width='3.29944'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;

            @include vw {
                width: get-vw(9px);
                height: get-vw(15px);
            }
        }

        &:hover {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15' fill='none' stroke='%23FEBD17'%3E%3Cpath d='M8.3418 13.0815L2.86254 7.60229L8.3418 2.12303' stroke-width='3.29944'/%3E%3C/svg%3E");
                //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15' fill='none' stroke='%23FEBD17'%3E%3Cpath d='M8.3418 13.0815L2.86254 7.60229L8.3418 2.12303' stroke-width='3.29944'/%3E%3C/svg%3E");
            }
        }
    }
}