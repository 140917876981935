.ad-item {
    .ad-image {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            min-height: 226px;
            max-height: 226px;
            object-fit: cover;
            border-radius: 5px;
        }
    }

    .ad-title {
        display: block;
        font-weight: 500;
        color: $black-color;

        &:hover {
            color: $primary-color;
        }
    }

    .action-block {
        margin-left: auto;
    }

    .button-secondary {
        font-size: 15px;
        padding: 9px 20px;

        @include tablet-small {
            font-size: 12px;
        }
    }
}

.shop-name {
    .icon {
        img {
            width: 33px;
            height: 33px;
            border-radius: 50%;
        }
    }
}

.sponsored-ad-wrapper {
    .row {
        >.slick-slider {
            @include mobile {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.slick-col {
    .slick-slide {
        padding-left: calc(var(--bs-gutter-x) * .5);
        padding-right: calc(var(--bs-gutter-x) * .5);

        @include mobile {
            // padding-left: 0;
            // padding-right: 0;
        }
    }

    .slick-arrow {
        color: #000;
        font-size: 0;
        line-height: 1;
        width: 50px;
        height: 50px;
        background-color: $primary-color;
        border-radius: 50%;

        &.slick-disabled {
            background-color: #D6D6D6;
            cursor: not-allowed;
        }
    }

    .slick-next {
        top: -35px;
        right: 30px;
        left: auto;

        &:before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='16' viewBox='0 0 11 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.12061 1.83603L8.14785 7.86328L2.12061 13.8905' stroke='white' stroke-width='3.62942'/%3E%3C/svg%3E%0A");
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 11px;
            height: 16px;
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }

    .slick-prev {
        top: -35px;
        right: 90px;
        left: auto;

        &:before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width=%2711%27 height=%2716%27 viewBox=%270 0 11 16%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M8.87549 13.89L2.84824 7.86271L8.87549 1.83546%27 stroke=%27white%27 stroke-width=%273.62942%27/%3E%3C/svg%3E%0A");
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 11px;
            height: 16px;
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }

    .slick-next:before,
    .slick-prev:before {
        color: #000;
    }
}