.contact-us-mian {
    padding: 55px 0;

    @include vw {
        padding: get-vw(110px) 0;
    }
}

.contact-us-wrapper {
    .has-img {
        img {
            max-width: 100%;
            width: 100%;
            height: 465px;
            object-fit: cover;

            @include vw {
                height: get-vw(930px);
            }
        }
    }
}

.main-form {
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 32px 20px;

    @include vw {
        border-radius: get-vw(20px);
        padding: get-vw(64px) get-vw(40px);
    }

    .form-action {
        .button {
            width: 100%;
        }
    }
}

.content-page {
    min-height: calc(100vh - 108px);
    padding: 0 50px;

    @include vw {
        min-height: calc(100vh - #{get-vw(108px)});
        padding: get-vw(50px) 0;
    }

    @include tablet-small {
        padding: 0 0px;
    }
}